<template>
  <div class="contact">
    <Banner/>
    <div class="firstPlate">
      <div class="solutionPublicTitle">
        <p>联系我们</p>
        <p>CONTACT US</p>
      </div>
      <div class="hy_lxwm_body">
        <div class="contact_info">
          <div class="contact_info_top">
            <p class="company_name">
              <span>慧言科技（天津）有限公司</span>
              <span>Huiyan Technology (Tianjin) Co.,Ltd.</span>
            </p>
            <p><span>地址：天津市河北区律纬路168号诺德中心二期21楼21层</span></p>
            <p><span>邮箱：bd@huiyan-tech.com</span></p>
            <p>
              <span>
                <span>电话：</span>
                <span>
                  022-23726181 (常规咨询、商务咨询)<br>
<!--                  刘经理&nbsp;&nbsp;156-9220-3015（商务咨询）<br>-->
<!--                  许经理&nbsp;&nbsp;189-1031-9816（商务咨询）-->
                </span>
              </span>
            </p>
          </div>
          <div class="contact_info_bottom">
            <p><span>微信订阅号</span></p>
            <img src="@/assets/imgs/bottom_wc.jpg" alt="">
          </div>
        </div>
        <div class="contact_map">
          <div id="container" class="containerMap"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner"
export default {
  name: "Contact",
  components: {
    Banner,
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap(){
      //步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
      //设置地图中心点
      let myLatlng = new window.qq.maps.LatLng(39.167927,117.202304);
      //定义工厂模式函数
      let myOptions = {
        zoom: 18, //设置地图缩放级别
        center: myLatlng, //设置中心点样式
      }
      //获取dom元素添加地图信息
      window.map = new window.qq.maps.Map(document.getElementById("container"), myOptions);
      new window.qq.maps.Marker({
        position: myLatlng,
        map: window.map,
        type: 'bounce'
      });
    },
  }
}
</script>

<style lang="less" scoped>
.firstPlate {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  .hy_lxwm_body{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 1200px;
    margin: 0 auto 94px;
    >div{
      position: relative;
      width: 50%;
    }
    .contact_info{
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      height: 430px;
      .contact_info_top{
        display: flex;
        flex-wrap: wrap;
        p{
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          >span{
            display: flex;
            width: 100%;
            margin-bottom: 10px;
            font-size: 18px;
            color: #333;
          }
        }
        p.company_name span:first-child{
          margin-bottom: 5px;
          font-size: 24px;
        }
        p.company_name span:last-child{
          margin-bottom: 37px;
          font-size: 14px;
        }
      }
      .contact_info_bottom{
        display: flex;
        flex-wrap: wrap;
        p{
          display: flex;
          width: 100%;
          span{
            display: block;
            margin-bottom: 10px;
            font-size: 18px;
            color: #333;
          }
        }
        img{
          width: 140px;
          height: 140px;
        }
      }
    }
    .containerMap {
      height: 495px;
      /deep/ .smnoprint {display: none}
      /deep/ div {
        div:nth-child(2) {
          a {
            display: none!important;
          }
        }
        div:nth-child(3) {
          span {
            display: none!important;
          }
        }
      }
    }
  }
}
</style>